import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SubmitEntry from '../SubmitEntry';

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreensStep6 extends Component {
    render() {
        const {onNext} = this.props;
        return (
            <>
                <div className={"step-overlay"}>
                    <div className="tip6"><a onClick={onNext} href="#">&nbsp;</a></div>
                </div>
            </>
        )
    }
}
