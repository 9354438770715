import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

//login screen which used for processing all functions connected to authentication to the app
export default
@inject('store')
@observer
class LoginScreen extends Component {
    //component state
    state = {
        email: {
            emailValue: '',
            emailChanged: false,
            emailValid: false
        },
        pass: {
            passValue: '',
            passChanged: false,
            passValid: false
        }
    }

    //function for confirm of auth details
    onInputConfirm = () => {
        this.props.store.app.authLoad(this.state.email.emailValue, this.state.pass.passValue);
    }

    //function for keeping component state up with inputs data
    onInputChange = (e, currInput) => {
        let el = e.target || e.currentTarget,
            newState = {};

        newState[currInput] = {};
        newState[currInput][currInput + 'Value'] = el.value || '';
        newState[currInput][currInput + 'Changed'] = !!el.value.length;
        newState[currInput][currInput + 'Valid'] = el.validity && el.value.length ? el.validity.valid : false;

        this.setState(newState);
    }

    //function for clearing authentication input
    onInputClear = (currInput) => {
        let newState = {};

        newState[currInput] = {};
        newState[currInput][currInput + 'Value'] = '';
        newState[currInput][currInput + 'Changed'] = false;
        newState[currInput][currInput + 'Valid'] = false;

        this.props.store.app.clearStatuses(true);

        this.setState(newState);
    }

    render() {
        let {
            email: { emailValue, emailChanged, emailValid },
            pass: { passValue, passChanged, passValid }
        } = this.state;

        return (
            <form action="#">
                <div className="order-input-wrapper">
                    <input
                        type="email" id="email-input"
                        className="wo-confirm"
                        placeholder="Enter your email"
                        onChange={(e)=>{this.onInputChange(e,'email');}}
                        value={emailValue}
                    />
                    <div className={classNames("input-clear input-btn btn", { 'disabled': !emailChanged })}
                         onClick={emailChanged ? () => {this.onInputClear('email');} : (() => {})}
                    ></div>
                </div>
                <div className="order-input-wrapper">
                    <input
                        type="password" id="pass-input"
                        placeholder="Enter your pass"
                        onChange={(e)=>{this.onInputChange(e,'pass');}}
                        value={passValue}
                    />
                    <div className={classNames("input-clear input-btn btn", { 'disabled': !passChanged })}
                         onClick={passChanged ? () => {this.onInputClear('pass');} : (() => {})}
                    ></div>
                    <div className={classNames("input-enter input-btn btn", { 'disabled': !emailChanged || !passChanged || !emailValid || !passValid })}
                         onClick={emailValid && passValid ? this.onInputConfirm : (() => {})}
                    ></div>
                </div>
                <div className="order-input-wrapper">
                    <a href="https://app.post-hawk.com/users/sign_up" rel="noopener noreferrer" target="_blank" className="secondary-link">Want Sign Up?</a>
                </div>
            </form>
        )
    }
}
