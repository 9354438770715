import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreensStep3 extends Component {
    render() {
        const {onNext} = this.props;
        return (
            <>
                <div className={"step-overlay"}>
                    <div className="tip3"><a onClick={onNext} href="#">&nbsp;</a></div>
                </div>
                <div className="tip-description">
                    <div className="box">
                        <p>Note: Order numbers can be found in two places on individual order pages, as highlighted below. Copy and paste either order number (including prefixes and suffixes, e.g. #) into the search bar here and click the arrow button to proceed.</p>
                    </div>
                    <div className="images">
                        <div className="left">
                            <div className="element">
                                <b>Order page</b>
                                <div className="image">
                                    <img src="/assets/img/tutorial/screen3_info_2.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="element">
                                <b>Unique url number</b>
                                <div className="image">
                                    <img src="/assets/img/tutorial/screen3_info_3.png" alt=""/>
                                </div>
                            </div>
                            <div className="element">
                                <b>Order number</b>
                                <div className="image">
                                    <img src="/assets/img/tutorial/screen3_info_1.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
