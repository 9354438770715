import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

//component for processing of order input manipulation
export default
@inject('store')
@observer
class OrderInput extends Component {
    //component state
    state = {
        orderValue: '',
        changed: false,
        valid: false
    }

    //function for keeping component state up with input data
    onInputChange = (e) => {

        let el = e.target,
            value = el.value,
            prevValue = this.state.orderValue,
            newState = {
                orderValue: value,
                //.match(/^(d+)$/) || value === '' ? value : prevValue,
                changed: value !== '',
                valid: value === '' ? false : el.validity.valid
            };
        this.setState(newState);
    }

    //function for confirm of orderId details
    onInputConfirm = () => {
        this.props.store.app.orderFetchLoad( this.state.orderValue);
    }

    //function for clearing orderId input
    onInputClear = () => {
        let newState = {
                orderValue: '',
                changed: false,
                valid: false
            };

        this.props.store.app.clearStatuses(true);

        this.setState(newState);       
    }

    render() {
        let { orderValue, changed, valid } = this.state;
        let { tutorialEnabled } = this.props.store.app;

        let orderNumber = orderValue;

        if([4,5].includes(tutorialEnabled)) {
            orderNumber = 11041421805614;
        }
        else if ([2,3,6].includes(tutorialEnabled)) {
            orderNumber = '';
        }

        return (
            <Fragment>
                <div className="order-input-wrapper">
                    <h3 class="sub-section-title">Order number:</h3>
                    <input 
                        type="text" id="order-input"
                        onChange={this.onInputChange}
                        placeholder="Enter order number here"
                        value={orderNumber}
                    />
                    <div className={classNames("input-clear input-btn btn", { 'disabled': !changed && !tutorialEnabled })}
                        onClick={changed ? this.onInputClear : (() => {})}
                    ></div>
                    <div className={classNames("input-enter input-btn btn", { 'disabled': (!changed || !valid) && !tutorialEnabled })}
                        onClick={valid ? () => { this.onInputConfirm(); } : (() => {})}
                    ></div>
                </div>
            </Fragment>
        )
    }
}
