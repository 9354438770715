import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Cookies from "js-cookie";

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class ScreenPassword extends Component {

    constructor() {
        super();
        this.form = React.createRef();
    }

    state = {
        password: '',
        new_password: ''
    };

    onSubmit = (event) => {
        event.preventDefault();
        const form = this.form.current;
        const { user } = this.props;

        const password = form.set_password,
              confirm_password = form.new_set_password;

        if(form.reportValidity()) {
            this.props.store.app.actionsSetPassword({
                password: password.value,
                password_confirmation: confirm_password.value,
                password_token: form.spt.value
            }, (response) => {
                if(typeof response.success !== "undefined") {
                    user.data.user.spt = false;
                    Cookies.set('auth_info', user, { 'max-age': ( user.data.user.exp_time ) });
                    this.props.onNext();
                }
            });
        }
    };

    onPasswordsMatch = () => {
        const form = this.form.current;
        const password = form.set_password,
            confirm_password = form.new_set_password;

        if(password.value !== confirm_password.value) {
            confirm_password.setCustomValidity('Passwords do not match');
        }
        else {
            confirm_password.setCustomValidity('');
        }
    };

    render() {
        const { user } = this.props;
        return (
            <>
                <div className="tip-description nmt">
                    <div className="box">
                        <h2 className={"text-center"}>Set your password</h2>
                        <p className={"np"}>Now that you know how to use the tool, let’s complete the setup of your account.</p>
                        <form ref={this.form} onSubmit={this.onSubmit}>
                            <input id={"spt"} type="hidden" value={user.data.user.spt} name={"token"}/>
                            <p>Please set your own password.</p>
                            <br/>
                            <div className="field">
                                <input onInput={this.onPasswordsMatch} minLength={6} autoComplete={false} type="password" id={"set_password"} name={"password"} placeholder={"Enter password"} required/>
                            </div>
                            <div className="field">
                                <input onInput={this.onPasswordsMatch} minLength={6} autoComplete={false} type="password" id={"new_set_password"} name={"new-password"} placeholder={"Enter password again to confirm"} required/>
                            </div>
                            <p>Note: Your username will be the email address associated with this Shopify account.</p>
                            <div className="field text-center">
                                <input type="submit" value={"Next"}/>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
