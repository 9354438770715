//constant that selects whether we can set values outside of state actions
export const STRICT_MODE = 'observed';
//constant that selects where all api requests follows to
export const API_ENDPOINT = '/api';
//constant posthawk client id
export const CL_ID = '95f61c4bbcd5a97afb4c0c238632d11c1419cdb9f21df6659c671ca7f1d06211';
export const REACT_APP_SHOPIFY_API_KEY = 'd8ca43814ddf682e69d29054a8c63ad4';
//constant posthawk client secret
export const CL_SC = 'ed767cb21b58de68177e9ad2ff64b0755dd662c7fc73a5463587e1a7d9ed1e51';
//constant posthawk authorization header
export const AUTHORIZATION = `Basic ${btoa(`${CL_ID}:${CL_SC}`)}`;
//constant that selects which color class would be used for each resolution
export const RESOLUTION_CLASSES = {
  'Sent replacement': 'dark-blue',
  'Sent Replacement': 'dark-blue',
  'Submitted claim': 'orange',
  'Submitted Claim': 'orange',
  'Issued return': 'light-red',
  'Issued Refund/Credit': 'light-red',
  'Initiated return/exchange': 'light-blue',
  'Initiated Return/Exchange': 'light-blue',
  'Asked customer to wait': 'light-green',
  'Asked Customer to Wait': 'light-green',
  'Shipment found': 'violet',
  'Shipment Found': 'violet',
  'Default': 'default-black'
};
export const TOAST_DEF_SETTINGS = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false
};
//constant api default actions
export const DEFAULT_ACTIONS = {
  "data": [
    {
      "type": "status",
      "id": 1,
      "name": "Status 1",
      "actions": [
        {
          "type": "detail",
          "id": 2,
          "name": "Detail 1",
          "actions": [
            {
              "type": "resolution",
              "id": 3,
              "name": "Resolution 1"
            },
            {
              "type": "fraud",
              "id": 4,
              "name": "Resolution 2"
            }
          ]
        },
        {
          "type": "detail",
          "id": 5,
          "name": "Detail 2",
          "actions": [
            {
              "type": "resolution",
              "id": 6,
              "name": "Issued return"
            },
            {
              "type": "fraud",
              "id": 7,
              "name": "Resolution 4"
            }
          ]
        }
      ]
    },
    {
      "type": "status",
      "id": 8,
      "name": "Status 2",
      "actions": [
        {
          "type": "detail",
          "id": 9,
          "name": "Detail 3",
          "actions": [
            {
              "type": "resolution",
              "id": 10,
              "name": "Resolution 5"
            },
            {
              "type": "resolution",
              "id": 11,
              "name": "Resolution 6"
            }
          ]
        },
        {
          "type": "detail",
          "id": 12,
          "name": "Detail 4",
          "actions": [
            {
              "type": "resolution",
              "id": 13,
              "name": "Resolution 7"
            },
            {
              "type": "resolution",
              "id": 14,
              "name": "Resolution 8"
            }
          ]
        }
      ]
    }
  ]
};
