import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SubmitEntry from '../SubmitEntry';

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreensStep4 extends Component {
    render() {
        const {onNext} = this.props;
        return (
            <>
                <div className={"step-overlay"}>
                    <div className="tip4"><a onClick={onNext} href="#">&nbsp;</a></div>
                </div>
                <div className="tip-description">
                    <div className="image-4">
                        <img src="/assets/img/tutorial/screen4_animate.gif" alt=""/>
                    </div>
                    <div className="text-center">
                        <SubmitEntry/>
                    </div>
                </div>
            </>
        )
    }
}
