//import of libraries
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AppContainer } from 'react-hot-loader';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';

//import of store, constants and scss files
import store from 'store';
import { STRICT_MODE } from 'common/const';
import 'common/scss/index.scss';

//import AppMain component
import AppMain from 'components/AppMain';

const isVarsProvided = window.shopifyApiKey !== '{{ apiKey }}' && window.shopOrigin !== '{{ shopOrigin }}';
let isAllowedRender = true,
    render = () => {};
/*
if(isVarsProvided) {
    if(window.top == window.self) {
        window.location.assign(`https://${window.shopOrigin}/admin/apps/${window.shopifyApiKey}`);
        isAllowedRender = false;
    } else {
        const app = createApp({
            apiKey: window.shopifyApiKey,
            shopOrigin: window.shopOrigin,
        });
    }
} else {
    isAllowedRender = false;
}
*/
//root html element
const root = document.getElementById('root');

//render of app
if(isAllowedRender) {
    render = () => {
        ReactDOM.render(
            <AppContainer>
                <Provider store={store}>
                    <AppMain/>
                </Provider>
            </AppContainer>,
            root,
        );
    };
} else {
    render = () => {
        ReactDOM.render(
            <>
                {
                    'The application has to run in a store environment...'
                }
            </>,
            root,
        );
    };
}

//mobx configuration
configure({
    enforceActions: STRICT_MODE
});

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();