import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SubmitEntry from '../SubmitEntry';

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreensStep7 extends Component {
    render() {
        const {onNext} = this.props;
        return (
            <>
                <div className={"step-overlay"}>
                    <div className="tip7"><a onClick={onNext} href="#">&nbsp;</a></div>
                </div>
                <div className="tip-description nmt">
                    <div className="box">
                        <h2 className={"text-center"}>Post Hawk Dashboard</h2>
                        <p>The Post Hawk Analytics Dashboard aggregates all delivery problems submitted by your team to Post Hawk and allows you to track delivery problems over time.</p>
                        <p>With more granular data, you can identify the root causes of your delivery problems and make data-driven fixes.</p>
                    </div>
                    <div className="image-7">
                        <img src="/assets/img/tutorial/screen7.png" alt=""/>
                    </div>
                </div>
            </>
        )
    }
}
