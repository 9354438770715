//import of constants
import { RESOLUTION_CLASSES } from 'common/const';

//function for generating of classes for responsive col elements
export const makeResponsiveCol = breakpointsObj => {
    let classString = 'col';

    Object.keys(breakpointsObj).forEach(key => {
        let breakpoint = `-${key}`;
        classString += ` col${breakpoint === '-all' ? '' : breakpoint}-${breakpointsObj[key]}`;
    });

    return classString;
};

//function for getting color class for resolution element
export const getResolutionClass = name => (RESOLUTION_CLASSES[name] || RESOLUTION_CLASSES['Default'])

//function for making immutable object/array
export const makeImmutable = arrOrObj => JSON.parse(JSON.stringify(arrOrObj))