import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreensStep1 extends Component {
    render() {
        const {onNext} = this.props;
        return (
            <div id={'hello-message'}>
                <div className="container">
                    <div className="box">
                        <h2>Welcome to</h2>
                        <img src="/assets/img/tutorial/logo.svg" alt="Post Hawk"/>
                        <h3>Your data driven solution to delivery problems.</h3>
                        <p>Post Hawk lets your customer service team consistently tag delivery problems reported by your customers and track those issues over time to identify their root causes.</p>
                        <p>Let’s take a few minutes to show you around and help you make the most out of the Post Hawk toolset.</p>
                        <div className="text-center">
                            <a onClick={onNext} href="#" className={"next-button"}>Next</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
