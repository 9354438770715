import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { makeResponsiveCol, getResolutionClass } from 'common/func';

import './PrevStatusList.scss';

//component used for rendering previous statuses for selected order
export default
@inject('store')
@observer
class PrevStatusList extends Component {
    render() {
        let { currentOrder } = this.props.store.app,
            { statuses } = currentOrder.data;

        return (
            <section className="prev-status-wrapper block-wrapper container row">
                <div className={classNames(
                    makeResponsiveCol({ 'all': '12' })
                )}>
                    <article className="sub-section-wrapper">
                        <h3 className="sub-section-title">Previous entries for this order</h3>
                        <div className="sub-section-content table-wrapper">
                            <table className="prev-status-table">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Issue</th>
                                        <th>Resolution</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Representative</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { statuses && statuses.length &&
                                        statuses.map( (status, index) => {
                                            let resolutionClass = getResolutionClass(status.actions[2].name);
                                            let email = 'unknown';
                                            if(status.user) {
                                                email = status.user.email;
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td>{status.actions[0].name}</td>
                                                    <td>{status.actions[1].name}</td>
                                                    <td>
                                                        <div className={`resolution-badge ${resolutionClass}`}>
                                                            <div className="dot"></div>
                                                            {status.actions[2].name}
                                                        </div>
                                                    </td>
                                                    <td>{status.date_time.replace(/(\|.*)/,'').trim()}</td>
                                                    <td>{status.date_time.replace(/(.*\|)/,'').trim()}</td>
                                                    <td>{email}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </article>
                </div>
            </section>
        )
    }
}
