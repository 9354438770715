import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreensStep2 extends Component {
    render() {
        const {onNext} = this.props;
        return (
            <div className={"step-overlay row container"}>
                <div className="tip2"><a onClick={onNext} href="#">&nbsp;</a></div>
            </div>
        )
    }
}
