//import constants and api functions
import { DEFAULT_ACTIONS, TOAST_DEF_SETTINGS } from 'common/const';
import { authApiPromise, orderFetchPromise, actionsFetchPromise, statusCreatePromise, setPasswordAction, getSyncStatusAction } from 'common/func/api';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

//action used to clear app state
export const clearAppAction = function () {
  this.orderEntered = false;
  this.orderConfirmed = false;

  this.tokenLoading = false;
  
  this.orderLoading = false;
  this.falseOrder = false;
  this.multipleOrder = false;
  this.orderReceived = false;
  this.currentOrder = {};
  this.currentOrderId = '';

  this.isSuspicious = false;

  this.statusSubmitLoading = false;
  this.statusCompleted = false;
  
  this.actionsLoading = false;
};

//action used on start of loading auth info from API
export const authLoadAction = function(login, pass, isInit, data) {
  let intervalFunc = (interval, login, pass) => {
    let authInfo = Cookies.getJSON('auth_info');

    if(!authInfo) {
      clearInterval(interval);
      if(login && pass) {
        this.authLoad(login, pass);
      }
    }
  };

  if(isInit) {
    if(data) {
      this.authConfirmed = true;
      this.auth(data, isInit);
      this.actionsFetchLoad();
      let interval = setInterval(() => { intervalFunc(interval); }, 1000);
      this.authIntervals.push(interval);
      return;
    }
  } else {
    this.authConfirmed = true;
    this.tokenLoading = true;
  }
  authApiPromise(login, pass, (data) => {
    Cookies.set('auth_info', { data: {
        'user': {
          'role': data.data.user.role,
          'email': data.data.user.email,
          'status': data.data.user.status,
          'shop_id': data.data.user['shop_id'],
          'spt': data.data.user['setup_password'],
          'exp_time': data.data["expires_in"]
        },
        'access_token': data.data["access_token"]
      }}, { 'max-age': ( data.data["expires_in"] ) });

    let interval = setInterval(() => { intervalFunc(interval, login, pass); }, 1000);
    this.authIntervals.push(interval);
    this.auth(data, isInit);
    this.actionsFetchLoad();
  },(err) => {
    let description = 'Error happened with login, please check you credentials.';

    if(err) {
      if(typeof err.error === 'string') {
        description = err.error;
      } else if(typeof err.error === 'object') {
        description = typeof err.error.message === 'string' ? err.error.message : description;
      }
    }

    toast.error(description, TOAST_DEF_SETTINGS);
    this.falseAuth();
  });
};

//action used on auth callback from API
export const authAction = function (data) {
  this.authConfirmed = true;
  this.tokenLoading = false;
  this.isFalseAuth = false;
  this.token = data.data["access_token"];
};

//action used of fail of auth
export const falseAuthAction = function () {
  this.tokenLoading = false;
  this.isFalseAuth = true;
};

//action used on start of loading order info from API
export const orderFetchLoadAction = function(orderId) {
  this.falseOrder = false;
  this.multipleOrder = false;
  this.orderReceived = false;
  this.orderLoading = true;
  this.statusSubmitCompleted = false;
  this.currentOrderId = orderId;
  orderFetchPromise((data) => {
    this.orderFetch(data);
  }, (err, code) => {
    if(code === 409) {
      this.orderMultipleFailed();
    }
    else {
        this.orderFailed();
    }
    this.clearStatuses();
  }, this.token, orderId);
};

//action used of fail of order fetching
export const orderFailedAction = function () {
  this.orderLoading = false;
  this.orderReceived = false;
  this.falseOrder = true;
};

//action used of fail of order fetching [multiple]
export const orderMultipleFailedAction = function () {
    this.orderLoading = false;
    this.orderReceived = false;
    this.multipleOrder = true;
};

//action used on order fetch callback from API
export const orderFetchAction = function (data) {
  this.orderLoading = false;
  this.orderReceived = true;
  this.currentOrder = data;
};

//action used on start of loading actions info from API
export const actionsFetchLoadAction = function() {
  this.actionsLoading = true;
  actionsFetchPromise((data) => {
    this.actionsFetch(data);
  }, (err) => {
    this.actionsFetch(DEFAULT_ACTIONS);
  }, this.token);
};

//action used on start of loading actions info from API
export const actionsSetPasswordAction = function(data, callback) {
  setPasswordAction(data, callback, this.token);
};

//action used on start of loading actions info from API
export const actionsGetSyncStatusAction = function(shop, callback, fast_response = false) {
    getSyncStatusAction(shop, callback, this.token, fast_response);
};

//action used on actions fetch callback from API
export const actionsFetchAction = function(data) {
  this.actionsLoading = false;
  this.actionsObj = data;
};

//action used on complete of status selection
export const completeStatusSelectionAction = function (actions) {
  this.statusCompleted = true;
  this.resultActions = actions;
};

//action used for clearing current selected statuses
export const clearStatusesAction = function (isClear) {
  this.isClearStatuses = isClear;

  if(isClear) {
    this.orderReceived = false;
    this.statusCompleted = false;
    this.falseOrder = false;
    this.statusSubmitCompleted = false;
    this.currentOrder = {};

    setTimeout(() => {
      this.clearStatuses(false);
    }, 200 );
  }
};

export const changeIsSuspiciousAction = function (value) {
  this.isSuspicious = value;
}

//action used on start of submitting status info to API
export const submitStatusLoadAction = function () {
  this.statusSubmitLoading = true;

  if(Array.isArray(this.resultActions[2])) {
    let timeOut = 0;

    this.resultActions[2].forEach( (resolution, ind, arr) => {
      let resolve, reject,
        currentActions = [this.resultActions[0], this.resultActions[1], resolution],
        isLast = ind === arr.length - 1;

      if(isLast) {
        resolve = (data) => {
          this.submitStatus(data);
        };
        reject = (err) => {
          this.submitStatus();
        };
      } else {
        resolve = () => {};
        reject = (err) => {
        };
      }

      setTimeout( () => {
        timeOut += 100;
        statusCreatePromise(resolve, reject, this.token, this.currentOrderId, currentActions, this.isSuspicious); 
      }, timeOut);
    });
  } else {
    statusCreatePromise((data) => {
      this.submitStatus(data);
    }, (err) => {
      this.submitStatus();
     }, this.token, this.currentOrderId, this.resultActions, this.isSuspicious);  
  }
  
};

//action used on status submit callback from API
export const submitStatusAction = function (data) {
  this.statusSubmitLoading = false;
  this.statusSubmitCompleted = true;
  this.clearApp();
};

export const setTutorialStatus = function (status=false) {
  this.tutorialEnabled = status;
}
