//import of mobx lib
import { observable, action } from 'mobx';

//import of actions
import {
    clearAppAction,
    authLoadAction,
    authAction,
    falseAuthAction,
    orderFetchLoadAction,
    orderFailedAction,
    orderMultipleFailedAction,
    orderFetchAction,
    actionsFetchLoadAction,
    actionsFetchAction,
    clearStatusesAction,
    changeIsSuspiciousAction,
    completeStatusSelectionAction,
    submitStatusLoadAction,
    submitStatusAction,
    setTutorialStatus,
    actionsSetPasswordAction,
    actionsGetSyncStatusAction
} from 'actions';

class App {
    @action.bound clearApp = clearAppAction;

    //if order number entered
    @observable orderEntered = false;
    //if order number confirmed
    @observable orderConfirmed = false;

    //if authentication data confirmed
    @observable authConfirmed = false;
    //if api returned error for authentication entered data
    @observable isFalseAuth = false;
    //if api token is loading
    @observable tokenLoading = false;
    //api token
    @observable token = '';
    @observable authIntervals = [];
    @action.bound authLoad = authLoadAction;
    @action.bound auth = authAction;
    @action.bound falseAuth = falseAuthAction;

    //if order details is loading
    @observable orderLoading = false;
    //if api returned error for entered order number
    @observable falseOrder = false;
    //if api order details received
    @observable orderReceived = false;
    //api order details
    @observable currentOrder = {};
    //current order number
    @observable currentOrderId = '';
    @action.bound orderFetchLoad = orderFetchLoadAction;
    @action.bound orderFailed = orderFailedAction;
    @action.bound orderMultipleFailed = orderMultipleFailedAction;
    @action.bound orderFetch = orderFetchAction;

    //if api status submission is in progress
    @observable statusSubmitLoading = false;
    //if api status submission is completed
    @observable statusSubmitCompleted = false;
    //if all status parts selected
    @observable statusCompleted = false;
    @action.bound clearStatuses = clearStatusesAction;
    @action.bound completeStatusSelection = completeStatusSelectionAction;
    @action.bound submitStatusLoad = submitStatusLoadAction;
    @action.bound submitStatus = submitStatusAction;

    //if api actions loading is in progress
    @observable actionsLoading = false;
    //api actions data
    @observable actionsObj = {};
    @observable isSuspicious = false;
    @observable tutorialEnabled = false;
    @action.bound setTutorialStatus = setTutorialStatus;
    //array of selected actions
    @observable resultActions = [];
    @action.bound changeIsSuspicious = changeIsSuspiciousAction;
    @action.bound actionsFetchLoad = actionsFetchLoadAction;
    @action.bound actionsFetch = actionsFetchAction;
    @action.bound actionsSetPassword = actionsSetPasswordAction;
    @action.bound actionsSyncStatus = actionsGetSyncStatusAction;
}

class RootStore {
    constructor() {
        this.app = new App();
    }
}

export default new RootStore();