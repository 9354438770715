import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TOAST_DEF_SETTINGS } from 'common/const';
import { alternativeAuthApiPromise, unsubscribeAction } from 'common/func/api';
import Loading from 'components/Loading';
import AppNavigation from 'components/AppNavigation';
import LoginScreen from 'components/LoginScreen';
import Notification from 'components/Notification';
import { makeResponsiveCol } from 'common/func';
import OrderInput from 'components/OrderInput';
import StatusSelector from 'components/StatusSelector';
import SubmitEntry from 'components/SubmitEntry';
import PrevStatusList from 'components/PrevStatusList';
import SetupScreens from 'components/SetupScreens';

import './AppMain.scss';

//Main entry point of application, chooses if rendering each part of application
export default
@inject('store')
@observer
class AppMain extends Component {
    static propTypes = {
        store: PropTypes.shape({
            app: PropTypes.shape({
                orderLoading: PropTypes.bool.isRequired,
                orderReceived: PropTypes.bool.isRequired
            })
        })
    }

    constructor(props) {
        super(props);

        Cookies.set('cookies-enabled', 'true', { expires: (365 * 3), path: '' });

        let isInit = true,
            shopifyAuth = false;
        
        // Shopify app access
        if(window.top != window.self) {
            isInit = false;
            shopifyAuth = false;
        }

        this.state = {
            isInit: isInit,
            policyOpen: false,
            shopifyAuth: shopifyAuth
        }
    }

    preAuth(authInfo) {
        if(authInfo) {
            this.props.store.app.authLoad(null, null, true, authInfo);
        }

        return !!authInfo;
    }

    onPolicyToggle = (isOpen) => {
        this.setState({
            policyOpen: isOpen
        })
    }

    onUnsubscribe = () => {
        const params = queryString.parse(window.location.search),
            { shop } = params;
        if(window.confirm('Are you sure you want to cancel your subscription?   ')) {
            window.alert('We will miss you! You have cancelled the subscription. Hope to see you in future');
            unsubscribeAction(shop);
        }
    }

    repeatTutorial = () => {
        this.props.store.app.setTutorialStatus(1);
    }

    onTutorialShow = (isOpen) => {
        Cookies.set('is-welcome', isOpen, { expires: (365 * 3), path: '' });
        this.repeatTutorial();
    }

    componentDidMount() {
        this.setState({
            isInit: false
        });

        if(window.location.pathname.includes('/shop_auth')) {
            const params = queryString.parse(window.location.search),
                { code, hmac, shop, timestamp } = params;

            toast.info("Authenticating...", TOAST_DEF_SETTINGS);

            alternativeAuthApiPromise(code, hmac, shop, timestamp, (data) => {
                Cookies.set('auth_info', { data: {
                        'user': {
                            'role': data.data.user.role,
                            'email': data.data.user.email,
                            'status': data.data.user.status,
                            'shop_id': data.data.user['shop_id'],
                            'spt': data.data.user['setup_password'],
                            'exp_time': data.data["expires_in"]
                        },
                        'access_token': data.data["access_token"]
                    }}, { 'max-age': ( data.data["expires_in"] ) });

                this.props.store.app.auth(data);
                this.props.store.app.actionsFetchLoad();
            },(err) => {
                let description = 'Error happened with login, please check you credentials.';

                if(err) {
                    if(typeof err.error === 'string') {
                        description = err.error;
                    } else if(typeof err.error === 'object') {
                        description = typeof err.error.message === 'string' ? err.error.message : description;
                    }
                }

                toast.error(description, TOAST_DEF_SETTINGS);
            });
        }
    }

    render() {
        let {
                tokenLoading, orderLoading, actionsLoading, statusSubmitLoading,
                orderReceived, statusSubmitCompleted, falseOrder, isFalseAuth, authConfirmed, multipleOrder, tutorialEnabled
            } = this.props.store.app,
            isAuthenticated = authConfirmed && !tokenLoading  && !isFalseAuth,
            cols = isAuthenticated ? { 'md': '12', 'lg': '10', 'xl': '10' } : { 'md': '12', 'lg': '10' },
            shopifyAuth = this.state.shopifyAuth;

        if(this.state.isInit) {
            isAuthenticated = this.preAuth(Cookies.getJSON('auth_info'));
        }

        if(Cookies.get('cookies-enabled') !== undefined && (isAuthenticated || shopifyAuth)) {
            if(Cookies.get('is-welcome') === undefined) {
                this.onTutorialShow(true);
            }
        }
        return (
          <div className="app-container">
            <Loading active={tokenLoading || orderLoading || actionsLoading || statusSubmitLoading}>
                <ToastContainer/>
                <div className="app-wrapper container row">
                    <AppNavigation repeatTutorial={this.repeatTutorial} onUnsubscribe={this.onUnsubscribe} />
                    <section className="status-wrapper block-wrapper row main-app" hidden={tutorialEnabled > 6 && tutorialEnabled !== false}>
                        <div className={classNames(
                            makeResponsiveCol(cols),
                            'center'
                        )}>
                            <article className="sub-section-wrapper">
                                <h3 className="sub-section-title text-center">{ isAuthenticated ? 'Enter an order number that had a delivery problem.' : ( shopifyAuth ? 'Checking your billing information...' : 'Login' ) }</h3>
                                <div className="sub-section-content statuses-sub-section">
                                    { !isAuthenticated && !shopifyAuth &&
                                        <LoginScreen/>
                                    }
                                    { isAuthenticated &&
                                        <OrderInput/>
                                    }
                                    { orderReceived && !actionsLoading &&
                                        <div hidden={tutorialEnabled}>
                                            <StatusSelector/>
                                        </div>
                                    }
                                    {
                                        ((isAuthenticated && !isFalseAuth && !orderReceived && !statusSubmitCompleted && !falseOrder && !multipleOrder && ![3,4,5,6].includes(tutorialEnabled))
                                        ||
                                        tutorialEnabled === 2)
                                        &&
                                        <div className="notification initial">
                                            <div className="notification-img"></div>
                                        </div>
                                    }
                                    { ((statusSubmitCompleted && tutorialEnabled === false) || (tutorialEnabled === 6)) &&
                                        <Notification message="Entry successfully submitted!" isSuccess={true}/>
                                    }
                                    { falseOrder && !tutorialEnabled &&
                                        <Notification message="We were unable to find an order that matches the order number you entered. Please double check you have entered the number correctly, including any prefix or suffix." isSuccess={false}/>
                                    }
                                    { multipleOrder && !tutorialEnabled &&
                                        <Notification message="It appears multiple orders include the number you entered. Please make sure you entered the order number correctly, including the prefix and suffix of the order" isSuccess={false}/>
                                    }
                                    { isFalseAuth && !tutorialEnabled &&
                                        <Notification message="Sorry, something went wrong with your search. We’ve notified our engineering team of this issue. Please try again or contact help@post-hawk.com if you need further assistance." isSuccess={false}/>
                                    }
                                    { isAuthenticated && orderReceived && !falseOrder && !tutorialEnabled &&
                                        <SubmitEntry/>
                                    }
                                </div>
                            </article>
                        </div>
                    </section>
                    {(isAuthenticated || shopifyAuth) && <SetupScreens/>}
                </div>
                { orderReceived && !tutorialEnabled &&
                    <PrevStatusList/>
                }
            </Loading>
          </div>
        )
    }
}
