import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import './SubmitEntry.scss';

//component which submits selected statuses of order
export default
@inject('store')
@observer
class SubmitEntry extends Component {
    render() {
        let { statusCompleted, submitStatusLoad, tutorialEnabled } = this.props.store.app;
        return (
            <div className={classNames(
                    "submit-entry-btn",
                    "btn",
                    { disabled: !statusCompleted && tutorialEnabled !== 5 }
                )}
                onClick={statusCompleted ? () => {submitStatusLoad()} : (() => {})}
            >
                Submit Status
            </div>
        )
    }
}
