import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SubmitEntry from '../SubmitEntry';

import './SetupScreens.scss';

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreensStep8 extends Component {
    render() {
        const {onNext} = this.props;
        return (
            <>
                <div className={"step-overlay"}>
                    <div className="tip8"><a onClick={onNext} href="#">&nbsp;</a></div>
                </div>
                <div className="tip-description nmt">
                    <div className="box">
                        <h2 className={"text-center"}>Chrome extension</h2>
                        <p>Using the Post Hawk Chrome extension makes recording delivery problems even easier. The extension adds clickable icons next to each order number that, when clicked, automatically pre-fill the order number and allow you to work alongside your order details.</p>
                        <p>Note: Upon first use, make sure to refresh the web page and/or close and relaunch Chrome.</p>
                    </div>
                    <div className="image-7">
                        <img src="/assets/img/tutorial/screen8.gif" alt=""/>
                    </div>
                </div>
            </>
        )
    }
}
