import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { makeResponsiveCol, getResolutionClass } from 'common/func';

import './AppNavigation.scss';

//component used for rendering previous statuses for selected order
export default
@inject('store')
@observer
class AppNavigation extends Component {
    navigationList = [
        { title: 'Email Post Hawk', url: 'mailto:info@post-hawk.com' },
        { title: 'Add Chrome Extension', url: 'https://chrome.google.com/webstore/detail/post-hawk-delivery-report/pfeojdoaddfickpcemomobpijffkpfek' },
        { title: 'Unsubscribe', onClick: (this.props.onUnsubscribe || (() => '')) },
        { title: 'Review Tutorial', onClick: (this.props.repeatTutorial || (() => '')) },
        { title: 'View Privacy Policy', url: 'https://app.post-hawk.com/terms-conditions' }
    ];

    ctaObj = {
        title: 'View Dashboard', url: 'https://app.post-hawk.com/'
    };

    render() {
        return (
            <section className="app-navigation-wrapper row">
                <div className={"navigation-list-wrapper " + classNames(
                    makeResponsiveCol({ 'all': '12' })
                )}>
                    <div className="navigation-list">
                        {
                            this.navigationList.map((item) => (
                                <a href={item.url || 'javascript:void(0);'} target={item.url ? "_blank" : "_self"} className="navigation-link">
                                    <div className="navigation-link-content" onClick={item.onClick || (() => '')}>
                                        { item.title }
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                    <div className="cta-wrapper">
                        <a href={this.ctaObj.url || 'javascript:void(0);'}  target={this.ctaObj.url ? "_blank" : "_self"}>
                            <div className="cta-button" onClick={this.ctaObj || (() => '')}>{this.ctaObj.title}</div>
                        </a>
                    </div>
                </div>
            </section>
        )
    }
}
