//import of constants
import { API_ENDPOINT, AUTHORIZATION, REACT_APP_SHOPIFY_API_KEY } from 'common/const';

//api authorization request
export const authApiPromise = (email, pass, resolve, reject) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/oauth/token`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": AUTHORIZATION
        },
        body: JSON.stringify({
            "email": email,
            "password": pass,
            "grant_type": "password"
        })
    })
    .then(response => {
        currentStatus = response;
        return response.json();
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const alternativeAuthApiPromise = (code, hmac, shop, timestamp, resolve, reject) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/auth/redirect_shop_auth`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": AUTHORIZATION
        },
        body: JSON.stringify({
            "code": code,
            "hmac": hmac,
            "shop": shop,
            "timestamp": timestamp
        })
    })
        .then(response => {
            currentStatus = response;
            return response.json();
        })
        .then(function (data) {
            if(typeof data.no_billing !== 'undefined') {
                window.top.location.href = data.no_billing;
            }
            else{
                if(currentStatus.ok || currentStatus.status < 400) {
                    resolve(data);
                    if(window.top == window.self && shop && REACT_APP_SHOPIFY_API_KEY) {
                        window.location.assign(`https://${shop}/admin/apps/${REACT_APP_SHOPIFY_API_KEY}`);
                    }
                } else {
                    reject(data);
                }
            }
        })
        .catch(function (error) {
            reject(error);
        });
};

//api order fetching request
export const orderFetchPromise = (resolve, reject, token, orderId) => {
    orderId = encodeURIComponent(orderId);
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/orders/${orderId}?access_token=${token}`, {
        method: 'get',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        }
    })
    .then(response => {
        currentStatus = response;
        return response.json();
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data, currentStatus.status);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

//api actions fetching request
export const actionsFetchPromise = (resolve, reject, token) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/actions?access_token=${token}`, {
        method: 'get',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        }
    })
    .then(response => {
        currentStatus = response;
        return response.json();
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

//api submit order status request
export const statusCreatePromise = (resolve, reject, token, orderId, actionsArr, isSuspicious) => {
    let currentStatus = {};

    fetch(`${API_ENDPOINT}/orders`, {
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Basic ${token}`
        },
        body: JSON.stringify({
            "access_token": token,
            "id": orderId,
            "actions": actionsArr,
            "suspicious": isSuspicious
        })
    })
    .then(response => {
        currentStatus = response;
        return response.json();
    })
    .then(function (data) {
        if(currentStatus.ok || currentStatus.status < 400) {
            resolve(data);
        } else {
            reject(data);
        }
    })
    .catch(function (error) {
        reject(error);
    });
};

export const unsubscribeAction = (shop = false) => {
    if(shop) {
        fetch(`${API_ENDPOINT}/billing/cancel`, {
            method: 'delete',
            headers: {
                "Content-type": "application/json",
                "Authorization": AUTHORIZATION
            },
            body: JSON.stringify({"shop": shop})
        })
            .then(response => {
                return response.json();
            })
            .then(function (data) {
                window.top.location.href = `https://${shop}/admin/apps/`;
            })
            .catch(function (error) {
                window.top.location.href = `https://${shop}/admin/apps/`;
            });
    }
};


export const setPasswordAction = (passwords = false, callback, token) => {
    if(passwords) {
        fetch(`${API_ENDPOINT}/users/password`, {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                "Authorization": `Basic ${token}`
            },
            body: JSON.stringify({
                ...passwords,
                "access_token": token
            })
        })
        .then(response => {
            return response.json();
        })
        .then(response => {
            callback(response);
        })
    }
};

export const getSyncStatusAction = (shop = false, callback, token, fast_response = false) => {
    if(shop) {
        fetch(`${API_ENDPOINT}/shops/sync_status?access_token=${token}&shop=${shop}&fast_response=${fast_response}`, {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                "Authorization": `Basic ${token}`
            }
        })
        .then(response => {
            return response.json();
        })
        .then(response => {
            callback(response);
        })
    }
};