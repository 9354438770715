import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';

import './SetupScreens.scss';
import SetupScreensStep1 from "./SetupScreensStep1";
import SetupScreensStep2 from "./SetupScreensStep2";
import SetupScreensStep3 from "./SetupScreensStep3";
import SetupScreensStep4 from "./SetupScreensStep4";
import SetupScreensStep5 from "./SetupScreensStep5";
import SetupScreensStep6 from "./SetupScreensStep6";
import SetupScreensStep7 from "./SetupScreensStep7";
import SetupScreensStep8 from "./SetupScreensStep8";
import ScreenPassword from "./ScreenPassword";
import ScreenSync from "./ScreenSync";
import Cookies from "js-cookie";

//component for displaying information based on message and status properties
export default
@inject('store')
@observer
class SetupScreens extends Component {

    state = {
        stepCounter: 1,
        stepTotal: 10,
        shopSynced: false,
        pending: false,
        skipSyncComplete: false
    };

    syncStatus = (fast_response = false) => {
        const params = queryString.parse(window.location.search),
            { shop } = params;
        if(this.props.store.app.tutorialEnabled !== false) {
            this.props.store.app.actionsSyncStatus(shop, (response) => {
                if(typeof response.success !== "undefined") {
                    this.setState({
                        shopSynced: true
                    });
                }
                else {
                    if(this.state.skipSyncComplete) {
                        this.setState({
                            skipSyncComplete: false
                        });
                    }
                    this.syncStatus();
                }
            }, fast_response);
            this.setState({
                pending: true
            });
        }
    };

    componentDidMount = () => {
        this.syncStatus(true);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.shopSynced === false && this.state.shopSynced === false && this.state.pending === false) {
            this.syncStatus();
        }
    };

    nextScreen = () => {
        if(this.state.stepCounter+1 <= this.state.stepTotal) {
            const new_page = this.state.stepCounter+1;
            this.setState({stepCounter: new_page});
            this.props.store.app.setTutorialStatus(new_page);
        }
        else {
            this.props.store.app.setTutorialStatus(false);
            this.setState({stepCounter: 1});
        }
    };

    render() {
        const { tutorialEnabled } = this.props.store.app;
        const { stepCounter, shopSynced, skipSyncComplete } = this.state;
        const user = Cookies.getJSON('auth_info');
        let content = '';

        if(tutorialEnabled !== false && tutorialEnabled !== stepCounter) {
            this.setState({stepCounter: tutorialEnabled});
        }

        if(user.data.user.spt !== false && !tutorialEnabled) {
            this.props.store.app.setTutorialStatus(1);
            this.setState({stepCounter: 1});
        } else if(shopSynced === false && !tutorialEnabled) {
            this.props.store.app.setTutorialStatus(10);
            this.setState({stepCounter: 10, skipSyncComplete: true});
        }

        if(tutorialEnabled) {
            switch (stepCounter) {
                case 1:
                    content = <SetupScreensStep1 onNext={this.nextScreen}/>; break;
                case 2:
                    content = <SetupScreensStep2 onNext={this.nextScreen}/>; break;
                case 3:
                    content = <SetupScreensStep3 onNext={this.nextScreen}/>; break;
                case 4:
                    content = <SetupScreensStep4 onNext={this.nextScreen}/>; break;
                case 5:
                    content = <SetupScreensStep5 onNext={this.nextScreen}/>; break;
                case 6:
                    content = <SetupScreensStep6 onNext={this.nextScreen}/>; break;
                case 7:
                    content = <SetupScreensStep7 onNext={this.nextScreen}/>; break;
                case 8:
                    content = <SetupScreensStep8 onNext={this.nextScreen}/>; break;
                case 9:
                    if(user.data.user.spt === false) {
                        this.nextScreen();
                    }
                    else {
                        content = <ScreenPassword user={user} onNext={this.nextScreen}/>;
                    }
                    break;
                case 10:
                    content = <ScreenSync onNext={this.nextScreen} shopSynced={shopSynced} skipConfirm={skipSyncComplete}/>; break;
                default:
                    content = <SetupScreensStep1 onNext={this.nextScreen}/>; break;
            }
        }

        return content
    }
}
